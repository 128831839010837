import _interopRequireDefault2 from "@babel/runtime/helpers/interopRequireDefault";
import _interopRequireWildcard2 from "@babel/runtime/helpers/interopRequireWildcard";
import _hook2 from "./hook";
import _uncontrollable2 from "./uncontrollable";
var exports = {};
var _interopRequireDefault = _interopRequireDefault2;
var _interopRequireWildcard = _interopRequireWildcard2;
exports.__esModule = true;
exports.useUncontrolledProp = exports.uncontrollable = exports.useUncontrolled = void 0;

var _hook = _interopRequireWildcard(_hook2);

exports.useUncontrolled = _hook.default;
exports.useUncontrolledProp = _hook.useUncontrolledProp;

var _uncontrollable = _interopRequireDefault(_uncontrollable2);

exports.uncontrollable = _uncontrollable.default;
export default exports;
export const __esModule = exports.__esModule,
      useUncontrolledProp = exports.useUncontrolledProp,
      uncontrollable = exports.uncontrollable,
      useUncontrolled = exports.useUncontrolled;